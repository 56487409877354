import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { url } from "./Global";
import axios from "axios";
import { createContext, useState, useEffect } from "react";
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") ? true : false
  );
  const [editPhoto, setEditPhoto] = useState(false);
  const [clickEdit, setClickedEdit] = useState(true);
  const [clkAdjBtn, setClkAdjBtn] = useState(false);
  const [clkEffBtn, setClkEffBtn] = useState(false);
  const [refImage, setRefImage] = useState("");
  const [orderDetails, setOrderDetails] = useState([]);
  const [authTokens, setAuthToken] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [loading, setLoading] = useState(true);
  const [liteImg, setLiteImg] = useState(true);
  const navigate = useNavigate();
  const [showCart, setShowCart] = useState(false);
  const [showMobileEditPnl, setShowMobileEditPnl] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const [userProfile, showUserProfile] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [born, setBorn] = useState("");
  const [bio, setBio] = useState("");
  const [nationality, setNationality] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [clickedSearch, setClickedSearch] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");
  const [resendOtpBtn, setResendOtpBtn] = useState(false);

  const [loadingArtWork, setloadingArtWork] = useState(false);

  const [load, setload] = useState(false);
  const [clickConfirm, setClickConfirm] = useState(false);

  const [showProgress, setShowProgress] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [aiRes, setAiRes] = useState(false);
  const [loadTime, setLoadTime] = useState(0);
  const [coverImg, setCoverImg] = useState("");

  const [buyHrdCopy, setBuyHrdCopy] = useState(false);

  const loginUser = async (e) => {
    e.preventDefault();
    const response = await fetch(`${url}/api/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: e.target.email.value,
        password: e.target.password.value,
      }),
    });
    const data = await response.json();

    if (response.status === 200 && data !== "Please Verify You Email Address") {
      setShowModal(false);
      setAuthToken(data);
      const d = jwt_decode(data.access);
      if (d.is_verified === true && d.is_norUser === true) {
        setUser(jwt_decode(data.access));
        localStorage.setItem("authTokens", JSON.stringify(data));
        setOpen(false);
        navigate("/");
      } else if (d.is_verified === true && d.is_boUser === true) {
        setUser(jwt_decode(data.access));
        localStorage.setItem("authTokens", JSON.stringify(data));
        setOpen(false);
        navigate("/");
      } else if (d.is_verified === true && d.is_superuser === true) {
        setUser(jwt_decode(data.access));
        localStorage.setItem("authTokens", JSON.stringify(data));
        navigate("/adminDeshBoard");
      } else {
        alert("Somethings wrong!");
        navigate("/");
      }
    } else if (
      response.status === 200 &&
      data === "Please Verify You Email Address"
    ) {
      alert("Please Verify Your Email Address");
      setResendOtpBtn(true);
    } else if (response.status === 401) {
      alert("Email or Password is incorrect!");
    } else {
      alert("Somethings wrong!");
    }
  };

  const logoutUser = async () => {
    if (authTokens) {
      setAuthToken(null);
      setUser(null);
      localStorage.removeItem("authTokens");

      axios({
        url: `${url}/api/logout/`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(authTokens?.access),
        },
        data: JSON.stringify({ refresh_token: authTokens?.refresh }),
      });
      // .then((res) => {
      //   if(res.status===200){

      //   }
      //   console.log(res)
      // })
    }
  };

  const profileImage = async () => {
    if (authTokens) {
      axios({
        url: `${url}/api/profileInfo/`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(authTokens?.access),
        },
      }).then((res) => {
        if (res.status === 200) {
          setProfileImg("data:image/png;base64," + res.data["imgUrl"]);
          setCoverImg(res.data["covimgUrl"]);
          setFirstName(res.data["first_name"]);
          setLastName(res.data["last_name"]);
          setBorn(res.data["born"]);
          setBio(res.data["bio"]);
          setNationality(res.data["nationality"]);
        }
      });
    }
  };

  const contextData = {
    user: user,
    authTokens: authTokens,
    loginUser: loginUser,
    logoutUser: logoutUser,
    showCart: showCart,
    setShowCart: setShowCart,
    liteImg: liteImg,
    setLiteImg: setLiteImg,
    userProfile: userProfile,
    showUserProfile: showUserProfile,
    profileImage: profileImage,
    profileImg: profileImg,
    firstName: firstName,
    lastName: lastName,
    born: born,
    setBorn: setBorn,
    bio: bio,
    setBio: setBio,

    coverImg: coverImg,
    setCoverImg: setCoverImg,

    nationality: nationality,
    setNationality: setNationality,

    resendOtpBtn: resendOtpBtn,
    setResendOtpBtn: setResendOtpBtn,

    showModal: showModal,
    setShowModal: setShowModal,
    selectedButton: selectedButton,
    setSelectedButton: setSelectedButton,

    clickedSearch: clickedSearch,
    setClickedSearch: setClickedSearch,

    loadingArtWork: loadingArtWork,
    setloadingArtWork: setloadingArtWork,

    isOpen: isOpen,
    setOpen: setOpen,

    editPhoto: editPhoto,
    setEditPhoto: setEditPhoto,

    clickEdit: clickEdit,
    setClickedEdit: setClickedEdit,

    load: load,
    setload: setload,

    orderDetails: orderDetails,
    setOrderDetails: setOrderDetails,

    clickConfirm: clickConfirm,
    setClickConfirm: setClickConfirm,

    showProgress: showProgress,
    setShowProgress: setShowProgress,

    percentage: percentage,
    setPercentage: setPercentage,

    aiRes: aiRes,
    setAiRes: setAiRes,

    loadTime: loadTime,
    setLoadTime: setLoadTime,

    darkMode: darkMode,
    setDarkMode: setDarkMode,

    refImage: refImage,
    setRefImage: setRefImage,

    showMobileEditPnl: showMobileEditPnl,
    setShowMobileEditPnl: setShowMobileEditPnl,

    clkAdjBtn: clkAdjBtn,
    setClkAdjBtn: setClkAdjBtn,

    clkEffBtn: clkEffBtn,
    setClkEffBtn: setClkEffBtn,

    buyHrdCopy: buyHrdCopy,
    setBuyHrdCopy: setBuyHrdCopy,
  };

  const updateToken = async () => {
    const response = await fetch(`${url}/api/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: authTokens?.refresh }),
    });
    const data = await response.json();

    if (response.status === 200) {
      setAuthToken(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
    } else {
      logoutUser();
    }

    if (loading) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      updateToken();
    }
    const mins = 1000 * 60 * 4 + 30;
    const interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, mins);
    return () => clearInterval(interval);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
