// const url = "http://aiinarts.com:8000";
// const neutal = "http://aiinarts.com:8000";
// const aiUrl = "http://182.163.112.207:7861";

// const url = "http://127.0.0.1:8000";
// const neutal = "http://127.0.0.1:8000";
// const aiUrl = "http://127.0.0.1:7861";

// Always use HTTPS for API requests, regardless of frontend protocol
const url =
  window.location.protocol === "https:"
    ? "https://aiinarts.com:8000"
    : "http://aiinarts.com:8000";
const neutal =
  window.location.protocol === "https:"
    ? "https://aiinarts.com:8000"
    : "http://aiinarts.com:8000";
const aiUrl =
  window.location.protocol === "https:"
    ? "https://182.163.112.207:7861"
    : "http://182.163.112.207:7861";

const PUBK = "0x83acCb9B4866cB0B930C45AC6735f60466b93234";
export { url, PUBK, aiUrl, neutal };
