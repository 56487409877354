import {Routes, Route } from "react-router-dom";
import { lazy,Suspense } from "react";
import { ReactQueryDevtools } from 'react-query/devtools'
import {QueryClientProvider,QueryClient} from 'react-query'
import {AuthProvider} from './context/AuthContext';
const Navbar = lazy(()=> import("./components/Navbar"));
const Footer = lazy(()=> import("./components/Footer"));
const Index = lazy(()=> import("./pages/Index"));
const Products = lazy(()=> import("./pages/Products"));
const Services = lazy(()=> import("./pages/Services"));
const IndivudualAllImages = lazy(()=> import("./pages/IndivudualAllImages"));
const PrivateRoute = lazy(()=> import("./utils/PrivateRoute"));
const BoRegister = lazy(()=> import("./pages/BoRegister"));
const Role = lazy(()=> import("./pages/Role"));
const ImageUploadLocal = lazy(()=> import("./pages/ImageUploadLocal"));
const HowToUseThisSite = lazy(()=> import("./pages/howToUseThisSite"));
const ArtistDeshBoard = lazy(()=> import("./pages/ArtistDeshBoard"));
const AdminDeshBoard = lazy(()=> import("./pages/AdminDeshBoard"));
const SingleImage = lazy(()=> import("./pages/SingleImage"));
const SellItem = lazy(()=> import("./pages/SellItem"));
const GenVerient = lazy(()=> import("./pages/GenVerient"));
const ShipingDetails = lazy(()=> import("./pages/PaymentGateway/ShipingDetails"));
const UpdateImage = lazy(()=> import("./pages/UpdateImage"));
const PaymentSuccess = lazy(()=> import("./pages/PaymentGateway/PaymentSuccess"));
const PaymentFail = lazy(()=> import("./pages/PaymentGateway/PaymentFail"));
const Vr = lazy(()=> import("./pages/Vr"));
const UpdateProfile = lazy(()=> import("./pages/UpdateProfile"));
const FrequentlyAskedQuestions = lazy(()=> import("./pages/FrequentlyAskedQuestions"));
const AllArtWork = lazy(()=> import("./pages/AllArtWork"));
const CreateCatagory = lazy(()=> import("./pages/CreateCatagory"));
const AllArtist = lazy(()=> import("./pages/AllArtist"));
const AboutArtist = lazy(()=> import("./pages/AboutArtist"));
const PRupload = lazy(()=> import("./pages/PRupload"));
const Libary = lazy(()=> import("./pages/Libary"));
const OnlinePaymentSuccess = lazy(()=> import("./pages/PaymentGateway/OnlinePaymentSuccess"));
const PromtAi = lazy(()=> import("./pages/PromtAi"));
const MyOrders = lazy(()=> import("./pages/MyOrders"));
const ConfirmOder = lazy(()=> import("./pages/ConfirmOder"));
const RemoveBackground = lazy(()=> import("./pages/RemoveBackground"));
const AIRemoval = lazy(()=> import("./pages/AIRemoval"));
const About = lazy(()=> import("./pages/About"));
const ImageUpload = lazy(()=> import("./pages/ImageUpload"));


function App() {
  const queryClient = new QueryClient()
  return (
    <>
    <Suspense fallback={<div style={{display: 'flex',width: '100%',height: '100vh',backgroundColor: '#1B2333',justifyContent: 'center',alignItems: 'center'}}><img style={{width: '154px',height: '154px',objectFit: 'contain'}} src='Preloder.gif' alt="preloder"/></div>}>
    <QueryClientProvider client={queryClient}>
    <AuthProvider>
    <Navbar />
    <Routes>
      <Route path="/" element={<Index/>} />
      <Route path="/allartwork" element={<AllArtWork/>}/>
      <Route path="/home" element={<Index/>} />      
      <Route path="/*" element={<PrivateRoute/>}>
        <Route path="imageUpload/" element={<ImageUpload/>} />
        <Route path="imageUpload/page/:id" element={<ImageUpload/>} />
        <Route path="imageUpload/:id" element={<IndivudualAllImages/>} />  
        <Route path="imageUpload/:id/page/:pid" element={<IndivudualAllImages/>} />  
        <Route path="boRegister" element={ <BoRegister/> } />
        <Route path="role" element={ <Role/> } />
        <Route path="artistDeshBoard" element={ <ArtistDeshBoard/> } />
        <Route path="artistDeshBoard/page/:id" element={ <ArtistDeshBoard/> } />
        <Route path="artistDeshBoard/:id" element={ <SingleImage/> } />
        <Route path="adminDeshBoard" element={ <AdminDeshBoard/> } />
        <Route path="imageUploadArtist/" element={<ImageUploadLocal/>} />
        <Route path="updateProfile/" element={<UpdateProfile/>} />
        <Route path="imageUploadArtist/:id" element={<UpdateImage/>} />
        <Route path="genVerient/" element={<GenVerient/>} />
        <Route path="createCatagory/" element={<CreateCatagory/>} />
        <Route path="promptAi/" element={<PromtAi/>} />
        <Route path="prUpload/" element={<PRupload/>} />   
        <Route path="prlibary/" element={<Libary/>} />  
        <Route path="orders/" element={<MyOrders/>} />   
        <Route path="orders/page/:id" element={ <MyOrders/> } />
        <Route path="confirmOrder/" element={ <ConfirmOder/> } />
        <Route path="backgroundChnage/" element={ <AIRemoval/> } />    
        <Route path="backgroundChnage/:id" element={ <RemoveBackground/> } />      
      </Route>      
      <Route path="/allartist" element={ <AllArtist/> } />
      <Route path="/aboutArtist/:id" element={<AboutArtist/>}/>
      <Route path="/products" element={ <Products/> } />
      <Route path="/howToUseThisSite" element={ <HowToUseThisSite/> } />
      <Route path="/frequentlyAskedQuestions" element={ <FrequentlyAskedQuestions/> } />
      <Route path="/services" element={ <Services/> } />
      <Route path="/about" element={ <About/> } />
      <Route path="/sellitem/:id" element={ <SellItem/> } />
      <Route path="/shippingDetailes" element={ <ShipingDetails/> } />
      <Route path="/success" element={<PaymentSuccess/>}/>
      <Route path="/success/:id" element={<OnlinePaymentSuccess/>}/>
      <Route path="/fail" element={<PaymentFail/>}/>
      <Route path="/vr" element={<Vr/>}/>
      <Route path="/photoEditor" element={<About/>}/>
    </Routes>
    <Footer/>
    </AuthProvider>
    <ReactQueryDevtools initialIsOpen={false} position='bottom-right'/>
    </QueryClientProvider>
    </Suspense>
    </>
  );
}

export default App;




// import { Routes, Route } from "react-router-dom";
// import Navbar from "./components/Navbar";
// import Footer from "./components/Footer"
// import Index from "./pages/Index";
// import Products from "./pages/Products";
// import ImageUpload from "./pages/ImageUpload";
// import Services from "./pages/Services";
// import About from "./pages/About";
// import IndivudualAllImages from './pages/IndivudualAllImages';
// import PrivateRoute from './utils/PrivateRoute';
// import BoRegister from './pages/BoRegister';
// import Role from './pages/Role';
// import ImageUploadLocal from './pages/ImageUploadLocal';
// import HowToUseThisSite from './pages/howToUseThisSite';
// import ArtistDeshBoard from './pages/ArtistDeshBoard';
// import AdminDeshBoard from './pages/AdminDeshBoard';
// import SingleImage from './pages/SingleImage';
// import SellItem from './pages/SellItem';
// import {AuthProvider} from './context/AuthContext';
// import GenVerient from './pages/GenVerient';
// import ShipingDetails from './pages/PaymentGateway/ShipingDetails';
// import UpdateImage from './pages/UpdateImage';
// import PaymentSuccess from './pages/PaymentGateway/PaymentSuccess';
// import PaymentFail from './pages/PaymentGateway/PaymentFail';
// import Vr from './pages/Vr';
// import UpdateProfile from './pages/UpdateProfile';
// import FrequentlyAskedQuestions from './pages/FrequentlyAskedQuestions';
// import AllArtWork from './pages/AllArtWork';
// import CreateCatagory from './pages/CreateCatagory';
// import AllArtist from './pages/AllArtist';
// import AboutArtist from './pages/AboutArtist';
// import PromtAi from './pages/PromtAi';
// import {QueryClientProvider,QueryClient} from 'react-query'
// import PRupload from './pages/PRupload';
// import Libary from './pages/Libary';
// import OnlinePaymentSuccess from './pages/PaymentGateway/OnlinePaymentSuccess';
// import MyOrders from './pages/MyOrders';
// import { ReactQueryDevtools } from 'react-query/devtools'
// import ConfirmOder from './pages/ConfirmOder';
// import RemoveBackground from './pages/RemoveBackground';
// import AIRemoval from './pages/AIRemoval';

// function App() {

//   const queryClient = new QueryClient()

//   return (
//     <>
//     <QueryClientProvider client={queryClient}>
//     <AuthProvider>
//     <Navbar />
//     <Routes>
//       <Route path="/" element={<Index/>} />
//       <Route path="/allartwork" element={<AllArtWork/>}/>
//       <Route path="/home" element={<Index/>} />      
//       <Route path="/*" element={<PrivateRoute/>}>
//         <Route path="imageUpload/" element={<ImageUpload/>} />
//         <Route path="imageUpload/page/:id" element={<ImageUpload/>} />
//         <Route path="imageUpload/:id" element={<IndivudualAllImages/>} />  
//         <Route path="imageUpload/:id/page/:pid" element={<IndivudualAllImages/>} />  
//         <Route path="boRegister" element={ <BoRegister/> } />
//         <Route path="role" element={ <Role/> } />
//         <Route path="artistDeshBoard" element={ <ArtistDeshBoard/> } />
//         <Route path="artistDeshBoard/page/:id" element={ <ArtistDeshBoard/> } />
//         <Route path="artistDeshBoard/:id" element={ <SingleImage/> } />
//         <Route path="adminDeshBoard" element={ <AdminDeshBoard/> } />
//         <Route path="imageUploadArtist/" element={<ImageUploadLocal/>} />
//         <Route path="updateProfile/" element={<UpdateProfile/>} />
//         <Route path="imageUploadArtist/:id" element={<UpdateImage/>} />
//         <Route path="genVerient/" element={<GenVerient/>} />
//         <Route path="createCatagory/" element={<CreateCatagory/>} />
//         <Route path="promptAi/" element={<PromtAi/>} />
//         <Route path="prUpload/" element={<PRupload/>} />   
//         <Route path="prlibary/" element={<Libary/>} />  
//         <Route path="orders/" element={<MyOrders/>} />   
//         <Route path="orders/page/:id" element={ <MyOrders/> } />
//         <Route path="confirmOrder/" element={ <ConfirmOder/> } />
//         <Route path="backgroundChnage/" element={ <AIRemoval/> } />    
//         <Route path="backgroundChnage/:id" element={ <RemoveBackground/> } />      
//       </Route>      
//       <Route path="/allartist" element={ <AllArtist/> } />
//       <Route path="/aboutArtist/:id" element={<AboutArtist/>}/>
//       <Route path="/products" element={ <Products/> } />
//       <Route path="/howToUseThisSite" element={ <HowToUseThisSite/> } />
//       <Route path="/frequentlyAskedQuestions" element={ <FrequentlyAskedQuestions/> } />
//       <Route path="/services" element={ <Services/> } />
//       <Route path="/about" element={ <About/> } />
//       <Route path="/sellitem/:id" element={ <SellItem/> } />
//       <Route path="/shippingDetailes" element={ <ShipingDetails/> } />
//       <Route path="/success" element={<PaymentSuccess/>}/>
//       <Route path="/success/:id" element={<OnlinePaymentSuccess/>}/>
//       <Route path="/fail" element={<PaymentFail/>}/>
//       <Route path="/vr" element={<Vr/>}/>
//       <Route path="/photoEditor" element={<About/>}/>
//     </Routes>
//     <Footer/>
//     </AuthProvider>
//     <ReactQueryDevtools initialIsOpen={false} position='bottom-right'/>
//     </QueryClientProvider>
//     </>
//   );
// }

// export default App;

